<template>
  <div class="container-fluid events">
     <div
        class="row my-5"
        v-if="
          currentUser.individual &&
          currentUser.individual.is_blog_editor |
            currentUser.individual.is_superadmin
        "
      >
        <div class="col-12 col-md-12 col-lg-12 col-xl-12">
          <button
            type="button"
            class="eventBtn"
            data-toggle="modal"
            data-target="#new_event"
            >
            Add Event
          </button>
        </div>
    </div>
    <upcoming-events />
    <current-event />
    <div class="row past_events mx-2" v-if="PastEvents.length > 0">
      <div class="col-md-12">
        <h2 class="title">Past Events</h2>
        <hr />
      </div>
      <div >
        <div class="row ">
          <div
            class="col-xs-12 col-sm-6"
            v-for="(event, key) in PastEvents"
            :key="key"
          >
          <div class="row">
            <div class="col-md-6 mb-3">
               <a  href="#">
                <img :src="`${appUrl()}${event.captionImage}`" class="img-fluid" style='height: 100%; width: 100%; object-fit: cover' />
              </a>
            </div>
            <div class="col-md-6">
              <h4> <b>{{ event.title }}</b></h4>
              <p class="">
                  {{ event.body && event.body.slice(0, 200) }} ... <a target="_blank" :href="`${event.sourceUrl}`">read more</a>
                </p>
                <span>
                  <b>Date: {{ event.date | FromNow }}</b>
                </span><br>
                <span>
                  <b class="text-danger">{{ event.location }}</b>
                </span> <br>
                <span><b>Languages:{{ event.languages }}</b></span>
                
            </div>
            
          </div>
          <div class="row mt-3 mb-5 d-flex justify-content-end" v-if="currentUser.individual && currentUser.individual.is_blog_editor | currentUser.individual.is_superadmin">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-6">
                   <button
                      data-toggle="modal"
                      data-target="#pastEventModal"
                      @click="getSingleEvent(event, key)"
                      class="btn btn-sm btn-primary"
                    >
                      Edit
                    </button>
                </div>
                <div class="col-md-6">
                   <button
                      data-toggle="modal"
                      @click="getResourceID(event.id, key)"
                      data-target="#PastEvent"
                      class="btn btn-sm btn-danger"
                    >
                      Delete
                    </button>
                </div>
              </div>
            </div>
          </div>
      </div>
     <!-- Delete Past Event -->
    <div
      class="modal fade"
      id="PastEvent"
      tabindex="-1"
      role="dialog"
      aria-labelledby="PastEventLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="PastEventLabel">
              Delete Current Event
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Are you sure you want to do this ?
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-danger"
              @click="deleteEvent()"
            >
              Confirm
            </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>

      <!-- add Past event modal -->
      <div
        class="modal fade"
        id="pastEventModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="pastEventModal"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="pastEventModalTitle">
                Add New Past Event
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
            <form @submit.prevent="updateEvent">
              <div class="form-group">
                <label for="name">Title</label>
                <input
                  type="text"
                  v-model="formData.title"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label for="email">Location</label>
                <input
                  type="text"
                  v-model="formData.location"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label for="phone">Date</label>
                <input
                  type="date"
                  v-model="formData.date"
                  class="form-control"
                />
              </div>
              <div class="form-group">
                <label for="exampleFormControlFile1">Caption Image</label>
                <div class="base-image-input" @click="chooseImage">
                  <img :src="imageUrl" class="preview_img" id="preview_image">
                   <input class="file-input" ref="fileInput" type="file" @change="handleCaptionImage">
                </div>
               
                <div class="alert alert-danger" v-if="captionError">
                  <p>{{ captionError }}</p>
                </div>
              </div>

              <div class="form-group">
                <label for="sourceUrl">Languages </label>
                <input
                  type="text"
                  class="form-control"
                  required
                  v-model="formData.languages"
                />
              </div>

              <div class="form-group">
                <label for="body">Body</label>
                <textarea
                  class="form-control"
                  v-model="formData.body"
                  rows="5"
                ></textarea>
              </div>
              <div class="modal-footer">
                <input
                  type="button"
                  class="btn btn-info"
                  data-dismiss="modal"
                  value="cancel"
                />
                <input type="submit"  class="btn btn-warning" value="update" />
              </div>
            </form>
          </div>
          </div>
        </div>
      </div>
    
    <!-- ADD NEW EVENT MODAL  -->
       <!-- add Event modal -->
    <div
        class="modal fade"
        id="new_event"
        tabindex="-1"
        role="dialog"
        aria-labelledby="new_envent">
        
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="eventTitle">
              Add Event
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="addcurrentEvent">
              <div class="form-group">
                <label for="title">Title</label>
                <input
                  type="text"
                  class="form-control"
                  id="title"
                  required
                  v-model="formData.title"
                />
              </div>
              <div class="form-group">
                <label for="author">Location</label>
                <input
                  type="text"
                  class="form-control"
                  id="author"
                  v-model="formData.location"
                />
              </div>
              <div class="form-group">
                <label for="publishedBy">Date</label>
                <input
                  type="date"
                  class="form-control"
                  required
                  v-model="formData.date"
                />
              </div>

              <div class="form-group">
                <label for="exampleFormControlFile1">Caption Image</label>
                <input
                  type="file"
                  class="form-control"
                  @change="handleCaptionImage"
                  required
                />
                <div class="alert alert-danger" v-if="captionError">
                  <p>{{ captionError }}</p>
                </div>
              </div>

              <div class="form-group">
                <label for="sourceUrl">Languages </label>
                <input
                  type="text"
                  class="form-control"
                  required
                  v-model="formData.languages"
                />
              </div>

              <div class="form-group">
                <label for="body">Body</label>
                <textarea
                  class="form-control"
                  v-model="formData.body"
                  rows="5"
                ></textarea>
              </div>

              <p class="alert alert-success py-2" v-if="success">
                {{ success }}
              </p>
              <p class="alert alert-danger" v-if="error">{{ error }}</p>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" class="btn btn-success">
                  <div
                    v-if="loading"
                    class="spinner-border spinner-border-sm text-light"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                  Add Event
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  
    <!-- END OF NEW EVENT MODAL -->
  </div>
</template>

<script>
import axios from "axios";
import Storage from "../../helpers/storage";
import url from "../../helpers/url";
import { mapGetters } from "vuex";
import CurrentEvent from "./CurrentEvent.vue";
import UpcomingEvents from "./UpcomingEvents.vue";
import moment from "moment";
export default {
  components: { CurrentEvent, UpcomingEvents },
  data() {
    return {
      token: "",
      PastEvents: [],
      captionError: "",
      success: "",
      error: "",
      key: "",
      id: "",
      token: "",
      loading: false,
      imageUrl: "",
      formData: {
        title: "",
        body: "",
        location: "",
        languages: "",
        captionImage: "",
        date: "",
      },
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },

  methods: {
    appUrl() {
      return url();
    },

    chooseImage() {
      this.$refs.fileInput.click();
    },

    getSingleEvent(data, key) {
      this.success = null;
      this.error = null;
      this.loading = false;

      this.id = data.id;
      this.key = key;
      this.formData.title = data.title;
      this.formData.body = data.body;
      this.formData.location = data.location;
      this.formData.languages = data.languages;
      this.formData.captionImage = data.captionImage;
      this.formData.date = data.date
        ? moment(data.date).format("").split("T")[0]
        : "";
      this.imageUrl = `${url()}${data.captionImage}`;
    },

    getResourceID(id, key) {
      this.success = null;
      this.error = null;
      this.loading = false;

      this.id = id;
      this.key = key;
    },

    fetchPastEvents() {
      axios
        .get(`${url()}/api/blog/fetch-past-event`)
        .then((response) => {
          console.log(response.data["Past Events"]);
          this.PastEvents = response.data["Past Events"];
        })
        .catch((err) => {
          if (err?.response?.data == undefined) {
            this.resourceError = "Network Error. Try again.";
          }
        });
    },

    handleCaptionImage(e) {
      let files = e.target.files;

      this.formData.captionImage = files;

      if (!this.formData.captionImage) {
        this.captionError = "Caption image is required";
      }

      if (files && files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
          document.getElementById("preview_image").src = e.target.result;
        };

        reader.readAsDataURL(files[0]);
      }
    },
       async addcurrentEvent() {
      try {
        this.loading = true;

        // validating caption image
        let newFormData = new FormData();

        if (this.formData.captionImage.length < 1) {
          this.captionError = "Caption image is required.";
          this.loading = false;
          return;
        } else {
          this.captionError = "";
        }

        const formartDate = new Date(this.formData.date).toISOString();
        newFormData.append("title", this.formData.title);
        newFormData.append("captionImage", this.formData.captionImage[0]);
        newFormData.append("date", formartDate);
        newFormData.append("body", this.formData.body);
        newFormData.append("location", this.formData.location);
        newFormData.append("languages", this.formData.languages);

        const response = await axios.post(
          `${url()}/api/blog/add-current-event`,
          newFormData,
          {
            headers: {
              Authorization: this.token,
            },
          }
        );

        this.loading = false;
        this.success = "Current Event Created successfully successfully.";
        this.formData = {};
        this.getCurrentEvents();
      } catch (err) {
        this.loading = false;
        console.log('error', err)
        if (err?.response?.data == undefined) {
          this.error = "Network error, check your connection and try again";
        }
      }
    },

    async addPastEvent() {
      try {
        this.loading = true;

        // validating caption image
        let newFormData = new FormData();

        if (this.formData.captionImage.length < 1) {
          this.captionError = "Caption image is required.";
          this.loading = false;
          return;
        } else {
          this.captionError = "";
        }

        const formartDate = new Date(this.formData.date).toISOString();
        newFormData.append("title", this.formData.title);
        newFormData.append("captionImage", this.formData.captionImage[0]);
        newFormData.append("date", formartDate);
        newFormData.append("body", this.formData.body);
        newFormData.append("location", this.formData.location);
        newFormData.append("languages", this.formData.languages);

        const response = await axios.post(
          `${url()}/api/blog/add-past-event`,
          newFormData,
          {
            headers: {
              Authorization: this.$session.get("entity"),
            },
          }
        );

        this.loading = false;
        this.success = "Past Event Created successfully successfully.";
        this.formData = {};
      } catch (err) {
        this.loading = false;
        if (err?.response?.data == undefined) {
          this.error = "Network error, check your connection and try again";
        }
      }
    },

    async updateEvent() {
      // console.log("You clicked me ")
      try {
        this.success = null;
        this.error = null;
        this.loading = true;

        let newFormData = new FormData();

        if (this.formData.captionImage.length < 1) {
          this.captionError = "featured Image required.";
          thi.loading = false;
          return;
        } else {
          this.captionError = "";
        }

        if (this.formData.date) {
          let formartDate = new Date(this.formData.date).toISOString();
          newFormData.append("date", formartDate);
        } else {
          newFormData.append("date", "");
        }

        // let FormatDate = new Date(this.formData.date).toISOString().split('T')[0]

        // console.log(FormatDate)

        newFormData.append("title", this.formData.title);
        newFormData.append("location", this.formData.location);
        newFormData.append("languages", this.formData.languages);
        // newFormData.append("date", FormatDate );
        newFormData.append("body", this.formData.body);
        newFormData.append("captionImage", this.formData.captionImage[0]);

        const response = await axios.patch(
          `${url()}/api/blog/update-current-event/${this.id}/`,
          newFormData,
          {
            headers: {
              Authorization: this.token,
            },
          }
        );

        this.CurrentEvents[this.key] = response.data;
      } catch (err) {
        this.loading = false;
        if (err?.response?.data == undefined) {
          this.error = "Network Error. Try again.";
        }
      }
    },

    async deleteEvent() {
      try {
        this.success = null;
        this.error = null;
        this.loading = true;
        const response = await axios.delete(
          `${url()}/api/blog/delete-current-event/${this.id}/`,
          {
            headers: {
              Authorization: this.token,
            },
          }
        );
        this.success = "Past Event deleted successfully";
        this.PastEvents.splice(this.key, 1);
        this.fetchPastEvents();
      } catch (err) {
        this.loading = false;
        if (err?.response?.data == undefined) {
          this.error = "Network Error. Try again.";
        }
      }
    },
  },
  mounted() {
    this.token = Storage.get("token");
    this.fetchPastEvents();
  },
};
</script>

<style  scoped>
.eventBtn {
  background-color: rgb(235, 41, 41);
  float: right;
  border: none;
  outline: none;
  color: #fff;
  font-size: 1.2em;
  padding: 0.6em;
  transition: all 0.5s;
}
.eventBtn:hover {
  outline: none;
  border: none;
  opacity: 0.7;
}
a.link {
  text-decoration: none;
  color: #000;
}
.past_events {
  margin-top: 100px;
}

.past_events > div:not(:last-of-type),
.past_events > div:not(:first-of-type) {
  margin-bottom: 40px;
}
.title {
  font-weight: bold;
  font-size: 28px;
}
.events {
  padding-bottom: 60px;
  padding-right: 12%;
  padding-left: 12%;
}
.past_events h5,
.past_events small,
.past_events span {
  font-weight: bold;
}
.past_events .lang {
  font-style: italic;
}

hr {
  border: 1px solid #000;
}

.img-responsive {
  height: 300px;
  width: 100%;
  object-fit: cover;
}

.view_more {
  border: 1px solid rgba(0, 0, 0, 0.7);
  outline: none;
  transition: all 0.5s;
}
.view_more:focus,
.view_more:active,
.view_more:hover {
  outline: none;
  background-color: rgb(235, 41, 41);
  color: #fff;
  border: 1px solid rgb(235, 41, 41);
}
.more {
  color: #8c8c8c;
}
@media screen and (max-width: 1100px) {
  .events {
    padding-right: 5%;
    padding-left: 5%;
  }
}
@media screen and (max-width: 768px) {
  .events {
    padding-bottom: 30px;
  }
  .past_events {
    margin-top: 70px;
  }
  .event-image {
    margin-bottom: 1em;
  }
}

</style>